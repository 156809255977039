import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "p-grid" }
const _hoisted_4 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_5 = { for: "inactive" }

import Patient, { PatientInformationFormDto, updatePatientWithPatientsInformation } from '@/models/Patient';
import { patientService } from '@/services/PatientService';
import { ServiceError } from '@/services/util/ServiceError';
import { useToast } from 'primevue/usetoast';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import CardComponent from '../CardComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PatientAccountState',
  setup(__props) {

const toast = useToast();
const { t } = useI18n();
const route = useRoute();
const patientId = Array.isArray(route.params.patientId) ? route.params.patientId[0] : route.params.patientId;

const patient = ref();
const patientInfo = ref<PatientInformationFormDto>({} as PatientInformationFormDto);

const editMode = ref(false);
const waitSubmit = ref(false);
const showValidation = ref(false);

const inactiveOptions = ref([
  { label: t(`patient.active`), value: false },
  { label: t(`patient.inactive`), value: true },
]);

onMounted(async () => {
  await loadPatient();
});

const loadPatient = async () => {
  const result = await patientService.find(patientId);
  if (!(result instanceof ServiceError)) {
    patient.value = result;
    patientInfo.value = new PatientInformationFormDto(result);
  }
};

const onSave = async () => {
  showValidation.value = true;

  await savePatient(updatePatientWithPatientsInformation(patient.value, patientInfo.value));
};

async function savePatient(patient: Patient) {
  waitSubmit.value = true;
  const result = await patientService.modify(patient.id, patient);

  if (result instanceof ServiceError) {
    toast.add({
      severity: 'error',
      summary: `${t('messages.notifications.errorEditPatient')} ${t('messages.notifications.tryLater')}`,
      life: 3000,
    });
  } else {
    editMode.value = false;
    showValidation.value = false;
    toast.add({ severity: 'success', summary: `${t('messages.notifications.successEditPatient')}`, life: 3000 });
  }
  waitSubmit.value = false;
}

const onCancelEdition = async () => {
  editMode.value = false;
  patientInfo.value = Object.assign({}, new PatientInformationFormDto(patient.value));
};

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(CardComponent, {
    cardTitle: _ctx.$t('configuration.patientAccountTitle'),
    "edit-mode": editMode.value
  }, {
    headingActions: _withCtx(() => [
      _withDirectives((_openBlock(), _createBlock(_component_Button, {
        class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-only-icon--dark", editMode.value ? 'p-disabled' : '']),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (editMode.value = !editMode.value))
      }, {
        default: _withCtx(() => [
          _createElementVNode("i", {
            class: "icon-edit",
            "aria-label": _ctx.$t('common.edit'),
            "aria-hidden": "true"
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["class"])), [
        [
          _directive_tooltip,
          _ctx.$t('common.edit'),
          void 0,
          { bottom: true }
        ]
      ])
    ]),
    cardContent: _withCtx(() => [
      (patientInfo.value)
        ? (_openBlock(), _createElementBlock("form", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('patient.state')) + " " + _toDisplayString(editMode.value ? '*' : ''), 1),
                _createVNode(_component_Dropdown, {
                  id: "inactive",
                  modelValue: patientInfo.value.inactive,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((patientInfo.value.inactive) = $event)),
                  options: inactiveOptions.value,
                  "option-label": "label",
                  "option-value": "value",
                  class: _normalizeClass(editMode.value ? '' : 'no-editable')
                }, null, 8, ["modelValue", "options", "class"])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    footerActions: _withCtx(() => [
      _createVNode(_component_Button, {
        class: "p-button-secondary p-button-medium",
        label: _ctx.$t('common.cancel'),
        onClick: onCancelEdition
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        class: "p-button p-button-info p-button-medium",
        loading: waitSubmit.value,
        label: _ctx.$t('common.save'),
        onClick: onSave
      }, null, 8, ["loading", "label"])
    ]),
    _: 1
  }, 8, ["cardTitle", "edit-mode"]))
}
}

})